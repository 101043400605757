import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../../common/layout/layout";
import SEO from "../../common/seo";

const BiographyPage: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "nino-desirelli.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout backUrl="/about" backText="About">
      <SEO title="Biography" />
      <div className="lg-half-width">
        <GatsbyImage
          image={data.image.childImageSharp.gatsbyImageData}
          alt="Nino Desirelli"
        />
        <p>
          Nino Desirelli (1934-2024) was an Italian painter, printmaker and
          musician.
        </p>
        <p>
          In the 50s he started a career as a saxophonist with the band “I
          Vichinghi” which toured Italian nightclubs playing alongside the likes
          of Gianni Basso, Chet Baker and many other Jazz player of the time at
          the Rupe Tarpea and at the Taverna Messicana. When “I Vichinghi”
          disbanded, at the beginning of the 60s, he finally pursued his other
          long standing passion and started his painting career.
        </p>
        <p>
          His research reflected his interest for wildlife and fluid dynamics,
          which are two essential elements of his works. His early paintings,
          drawings and prints are figurative. They depict animals in motion. The
          dynamic element is always present in his works, be it overtly apparent
          or implied. In subsequent works the wildlife and fluid dynamics
          elements gradually blend together into what becomes an exploration of
          space.
        </p>
      </div>
    </Layout>
  );
};

export default BiographyPage;
